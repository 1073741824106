import React, { useState, useEffect } from "react"
import Photo from "../../images/consultor.png"
import AlertIcon from "../../images/fill-1.png"
import { useFormik } from 'formik';
import { initialValues, validationSchema } from './formik';
import HeaderProgress from "../../components/HeaderProgress"
// GraphQL
import client from "../../service/graphql"
import mutations from "../../service/mutations"
import { navigate } from "gatsby"
import CheckIcon from '../../images/checkWhite.svg'

import {
  Container,
  Section,
  Header,
  Profile,
  User,
  Form,
  Label,
  Input,
  Button,
  Row,
  TermText,
  TermTextBlue,
  Circle,
} from "./styles"

import Alert from "../../components/alert"
import { Body, Buttons } from "../../steps/shared-styles"

import { formatNumber, extractNumber } from "../../utils";

const SignUp = () => {
  const [error, setError] = useState(null)
  const [tracking, setTracking] = useState({})
  const [selected, setSelected] = useState(false)

  const onSubmit = async (data) => {
    try {
      setError(null)
      const { cpf } = data;

      navigate('/success')
      // navigate('/serasaAnalytic', { state: { cpf, route: '/signup' } })
    } catch ({ response }) {
      setError(response.errors[0]
        ? response.errors[0].message
        : "ocorreu um erro inesperado");
    }
  }

  const formik = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
  });

  return (
    <div>
      <HeaderProgress description="Sobre você" percent={50} />
      <Container padding>
        <Section>
          <Body>
            <Header>
              <Profile>
                <User src={Photo} />
              </Profile>
              <Label><p>E aí? Se interessou pela Veriza? Faça seu pré cadastro e aproveite as melhores taxas!</p></Label>
            </Header>
            <Form>
              <Input
                placeholder="Digite seu CPF"
                type="cel"
                mask="999.999.999-99"
                maskChar=""
                value={formik.values.cpf}
                onChange={(e) => formik.setFieldValue('cpf', e.target.value)}
                error={formik.touched.cpf && formik.errors.cpf}
              />
            </Form>
            {formik.touched.cpf && formik.errors.cpf && <Alert icon={AlertIcon} description={formik.errors.cpf}></Alert>}

            <Buttons>
              <Button
                onClick={formik.submitForm}
                disabled={
                  formik.values.cpf.length < 14
                }
              >
                Tenho interesse!
              </Button>
            </Buttons>
          </Body>
        </Section>
      </Container>
    </div>
  )
}

export default SignUp
